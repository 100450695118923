import React, { Component } from "react";
import { Link } from 'react-router-dom';

import AuthService from "../services/auth.service";
import DevicesService from "../services/devices.service";

import { messaging } from "../init-fcm";

export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: undefined,
            fcmToken: undefined,
            isModerator: false,
            isAdmin: false
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if(user) {
            this.setState({
                currentUser: user,
                isModerator: user.user.roles.includes("ROLE_MODERATOR"),
                isAdmin: user.user.roles.includes("ROLE_ADMIN")
            });
    
            if(messaging) {
                Notification.requestPermission()
                    .then(()=>{
                        if(Notification.permission === "granted") {
                            try {
                                messaging.getToken().then(token => {
                                    DevicesService.addDevice(user.user.uid, token).then(
                                            response => {
                                                this.setState({fcmToken: token});
                                            },
                                            error => {
                                                console.log(error);
                                            }
                                        );
                                    });
                            }
                            catch(err) {
                                console.log(err);
                            }
                        }
                    })
                    .catch(error => {
                        if (error.code === "messaging/permission-blocked") {
                            console.log("Please Unblock Notification Request Manually");
                        } else {
                            console.log("Error Occurred", error);
                        }
                    });
        
                navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
            }
            else {
                console.error("This browser does not support FCM / HTTPS is required for FCM.");
            }
        }
    }

    render() {
        const { currentUser, isModerator, isAdmin } = this.state;

        return (
            <div className="container mt-3">
                {currentUser ? (
                    <div>
                        <h2>
                            Account Information
                        </h2>

                        <hr/>

                        <div id="page-content-wrapper">
                            <p>
                                <strong>Full Name:</strong>{" "}
                                {currentUser.user.name}
                            </p>

                            <p>
                                <strong>User Id:</strong>{" "}
                                {currentUser.user.uid}
                            </p>

                            <p>
                                <strong>Username:</strong>{" "}
                                {currentUser.user.username}
                            </p>

                            <p>
                                <strong>Email:</strong>{" "}
                                {currentUser.user.email}
                            </p>

                            <p className="break-text">
                                <strong>Session Auth Token:</strong>{" "}
                                {currentUser.accessToken.token}
                            </p>

                            <p className="break-text">
                                <strong>Refresh Auth Token:</strong>{" "}
                                {currentUser.refreshToken.token}
                            </p>

                            {this.state.fcmToken && (
                                <p className="break-text">
                                    <strong>FCM Token:</strong>{" "}
                                    {this.state.fcmToken}
                                </p>
                            )}

                            <div>
                                {(!isAdmin && !isModerator) && (<Link className="profile-link" to="/activity">View Activity</Link>)}
                            </div>

                            <div>
                                {(!isAdmin && !isModerator) && (<Link className="profile-link" to="/devices">View Devices</Link>)}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        <header className="jumbotron">
                            <h3>Error: Unauthorized</h3>
                        </header>
                    </div>
                )}
            </div>
        );
    }
}