import React, { Component } from "react";

import AuthService from "../services/auth.service";
import DevicesService from "../services/devices.service";

export default class Device extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            devices: [],
            currentUser: undefined
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser().user;

        if(user) {
            this.setState({
                loggedIn: true, currentUser: user
            },
                () => {
                    DevicesService.getDevices(user.uid).then(
                        response => {
                            let array = response.data.devices;

                            //V2 format update
                            let dataArray = response.data.data;
                            for (var j = 0; j < dataArray.length; j++) {
                                if (dataArray[j].type === "devices") {
                                    array = dataArray[j].entries;
                                }
                            }

                            for (var i = array.length - 1; i >= 0; --i) {
                                if (array[i].status === "deleted") {
                                    array.splice(i,1);
                                }
                            }

                            this.setState({devices: array});
                        },
                        error => {
                            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        
                            this.setState({
                                content: resMessage
                            });
                        }
                    );
                }
            );
        }
        else {
            this.setState({
                content: "Error: Unauthorized"
            });
        }
    }

    render() {
        const {content, devices} = this.state;

        return (
            <div className="container mt-3">
                {content!=="" ? (
                    <header className="jumbotron">
                        <h3>{content}</h3>
                    </header>
                ):(
                    <div>
                        <h2>
                            Devices
                        </h2>

                        <hr/>

                        {devices.length > 0 && (
                            <div>
                                {
                                    devices.map((device, index) => (
                                        <div key={device.uid + index} className="card bg-light mb-3 noPadding participant-card">
                                            <h2 className="card-header">
                                                {device.name} {device.uid === DevicesService.getDeviceId() && (<span>(Current Device)</span>)}
                                            </h2>
                                            <div className="card-body">
                                                <div><strong>Device Type:</strong> {device.type}</div>
                                                <div><strong>Last Active:</strong> {new Date(device.updated).toLocaleString()}</div>
                                                {device.ip && (<div><strong>Public IP:</strong> {device.ip}</div>)}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )}

                    </div>
                )}
            </div>
        )
    }
}