import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAUFX9Chbwsrc4lwIjTwrpfvJtRj78Ekq0",
    authDomain: "mylifeguard247.firebaseapp.com",
    databaseURL: "https://mylifeguard247.firebaseio.com",
    projectId: "mylifeguard247",
    storageBucket: "mylifeguard247.appspot.com",
    messagingSenderId: "472743297984",
    appId: "1:472743297984:web:ea2ccc98c26b2da622be25",
    measurementId: "G-WDCEBYZKFP"
});

const messaging = firebase.messaging.isSupported() ? initializedFirebaseApp.messaging() : undefined;

if(messaging) {
    messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        "BO07l8nF9OflmXweAlWBoCXl227igzlkDme4vZlu2r-Ba_YplItq-byQKY5KR7ZXjhc6-7q8JuXoWxpdJyk8uf0"
    );
}

export { messaging };