import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v2/';

class LogsService {
    getLogs(userId, page, maxResults) {
        return axios.get(API_URL + userId + '/logs?pageToken=' + page + '&maxResults=' + maxResults, { headers: authHeader() });
    }

    getLogsWithDateConstraints(userId, page, maxResults, start, end) {
        return axios.get(API_URL + userId + '/logs?pageToken=' + page + '&maxResults=' + maxResults + "&start=" + start + "&end=" + end, { headers: authHeader() });
    }
}

export default new LogsService();