import { DeviceUUID } from "device-uuid";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v2/';

class DevicesService {
    getDeviceId() {
        const deviceId = localStorage.getItem('deviceId');

        if(deviceId) {
            return deviceId;
        }
        else {
            const newDeviceId = uuidv4();
            localStorage.setItem("deviceId", newDeviceId);
            return newDeviceId;
        }
    }

    addDevice(userId, notificationToken=undefined, ipv4=undefined) {
        const du = new DeviceUUID().parse();

        let device = {
            "uid": this.getDeviceId(),
            "status": "enabled",
            "version": 2,
            "updated": new Date(),
            "type": "website",
            "name": du.platform,
            "userId": userId,
            "location": "Website",
            "swVersion": "1.0.0"
        };
    
        if(notificationToken) {
            device.notificationToken = notificationToken;
        }

        if(ipv4) {
            device.ip = ipv4;
        }

        let devices = [];
        devices.push(device);

        let entry = {
            "type": "devices",
            "entries": devices,
        }
        let data = [];
        data.push(entry);
        let body = {
            "data": data
        }

        return axios.put(API_URL + userId + "/devices", {
            body
        }, {headers: authHeader()})
    }

    getDevices(userId) {
        return axios.get(API_URL + userId + "/devices", {headers: authHeader()})
    }

    deleteDevice(userId, deviceId) {
        return axios.delete(API_URL + userId + "/devices?uid=" + deviceId + '&purge=true', {headers: authHeader()})
    }
}

export default new DevicesService();