import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v2/';

class UserService {
    findByEmail(userId, email) {
        return axios.get(API_URL + userId + '/users?email=' + email, { headers: authHeader() });
    }

    getUsers(userId, page, maxResults) {
        return axios.get(API_URL + userId + '/users?pageToken=' + page + '&maxResults=' + maxResults, { headers: authHeader() });
    }

    deleteUser(userId, uid) {
        return axios.delete(API_URL + userId + '/users?uid=' + uid + '&purge=true', { headers: authHeader() });
    }
}

export default new UserService();