import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, NavLink, Redirect } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import logoicon from "./images/ic_launcher_round.png";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import RegisterMod from "./components/register-mod.component";
import About from "./components/about.component";
import Profile from "./components/profile.component";
import Activity from "./components/activity.component";
import RemoteLogs from "./components/remote-logs.component";
import Session from "./components/sessions.component";
import Device from "./components/device.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import UserLogs from "./components/user-logs.component";

const refreshTokenRequestLatency = 5000;

class App extends Component {
    constructor(props) {
        super(props);

        console.log("Using server: " + process.env.REACT_APP_API_URL);

        this.addDeviceAndSetLocalStorage = this.addDeviceAndSetLocalStorage.bind(this);

        this.state = {
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
            userDefined: false,
            goToRoot: false
        };

        // eslint-disable-next-line no-extend-native
        Number.prototype.pad = function(size) {
            var s = String(this);
            while (s.length < (size || 2)) {s = "0" + s;}
            return s;
        }

        // eslint-disable-next-line no-extend-native
        Array.prototype.move = function(from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
        }
    }

    addDeviceAndSetLocalStorage(user) {
        this.setState({
            currentUser: user,
            showModeratorBoard: user.user.roles.some( role => role['name'] === 'ROLE_MODERATOR' ),
            showAdminBoard: user.user.roles.some( role => role['name'] === 'ROLE_ADMIN' ),
            userDefined: true
        });
        // ipv4().then(
        //     ip => {
        //         DevicesService.addDevice(user.user.uid, undefined, ip).then(
        //             response => {
        //                 this.setState({
        //                     currentUser: user,
        //                     showModeratorBoard: user.user.roles.some( role => role['name'] === 'ROLE_MODERATOR' ),
        //                     showAdminBoard: user.user.roles.some( role => role['name'] === 'ROLE_ADMIN' ),
        //                     userDefined: true
        //                 });
        //             },
        //             error => {
        //                 console.error(error);
        //                 AuthService.logout();
        //             }
        //         );
        //     },
        //     error => {
        //         DevicesService.addDevice(user.user.uid).then(
        //             response => {
        //                 this.setState({
        //                     currentUser: user,
        //                     showModeratorBoard: user.user.roles.some( role => role['name'] === 'ROLE_MODERATOR' ),
        //                     showAdminBoard: user.user.roles.some( role => role['name'] === 'ROLE_ADMIN' ),
        //                     userDefined: true
        //                 });
        //             },
        //             error => {
        //                 console.error(error);
        //                 AuthService.logout();
        //             }
        //         );
        //     }
        // );
    }

    componentDidMount() {
        function autoRefreshToken(time) {
            setTimeout(function() {
                AuthService.refreshToken().then(
                    response => {
                        if (response.data.accessToken) {
                            let data = response.data;
                            data.id = data.userId;
                            data.uid = data.userId;
                            localStorage.setItem("user", JSON.stringify(data));

                            const user = AuthService.getCurrentUser();
                            const timeUntilAccessTokenExpiry = user.accessToken.expiryDate - Date.now();

                            autoRefreshToken(timeUntilAccessTokenExpiry-refreshTokenRequestLatency);

                            return;
                        }
                        else {
                            AuthService.logout();
                        }
                    },
                    error => {
                        AuthService.logout();
                    }
                );
            }, time);
        }

        const user = AuthService.getCurrentUser();

        if (user) {
            const timeUntilAccessTokenExpiry = user.accessToken.expiryDate - Date.now();
            const timeUntilRefreshTokenExpiry = user.refreshToken.expiryDate - Date.now();

            autoRefreshToken(timeUntilAccessTokenExpiry-refreshTokenRequestLatency);

            if(timeUntilRefreshTokenExpiry <= 0) {
                AuthService.logout();
                this.setState({goToRoot : true});
            }
            else if(timeUntilAccessTokenExpiry <= 0) {
                AuthService.refreshToken().then(
                    response => {
                        if (response.data.accessToken) {
                            let data = response.data;
                            localStorage.setItem("user", JSON.stringify(data));
                            window.location.reload(true);
                        }
                        else {
                            AuthService.logout();
                        }
                    },
                    error => {
                        AuthService.logout();
                    }
                );
            }
            else {
                this.addDeviceAndSetLocalStorage(user);
            }
        }
        else {
            this.setState({userDefined: true});
        }
    }

    render() {
        const { currentUser, showModeratorBoard, showAdminBoard, userDefined } = this.state;

        return (
            <Router>
                {this.state.goToRoot && (
                    <Redirect to="/"></Redirect>
                )}

                <div>
                    <Navbar bg="dark" expand="lg" variant="dark" sticky="top">
                        <div className="container">
                            <Navbar.Brand href="/">
                            <img src={logoicon} width="30" height="30" style={{marginRight:4}} alt=""></img>
                               myLifeGuard</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />

                            {userDefined && (<Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    {showModeratorBoard && (
                                        <NavLink to={"/mod"} className="nav-link" activeClassName="active">Users</NavLink>
                                    )}
                                    {showAdminBoard && (
                                        <NavLink to={"/admin"} className="nav-link" activeClassName="active">Users</NavLink>
                                    )}
                                    {showAdminBoard && (
                                        <NavLink to={"/registermod"} className="nav-link" activeClassName="active">Create Moderator</NavLink>
                                    )}
                                    {(currentUser && !showModeratorBoard && !showAdminBoard) && (
                                        <NavLink to={"/sessions"} className="nav-link" activeClassName="active">Sessions</NavLink>
                                    )}
                                </Nav>
                                <Nav>
                                    {currentUser && (
                                        <NavLink to={"/profile"} className="nav-link" activeClassName="active">My Account</NavLink>
                                    )}
                                    {currentUser && (
                                        <div className="nav-link" onClick={()=>AuthService.logout()}>Log Out</div>
                                    )}
                                    {!currentUser && (
                                        <NavLink to={"/login"} className="nav-link" activeClassName="active">Login</NavLink>
                                    )}
                                    {!currentUser && (
                                        <NavLink to={"/register"} className="nav-link" activeClassName="active">Sign Up</NavLink>
                                    )}
                                </Nav>
                            </Navbar.Collapse>)}
                        </div>
                    </Navbar>

                    <div>
                        <Switch>
                            <Route exact path="/about" component={About} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/register" component={Register} />

                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/activity" component={Activity} />
                            <Route exact path="/sessions" component={Session} />
                            <Route exact path="/devices" component={Device} />

                            <Route exact path="/userlogs" component={UserLogs} />
                            <Route exact path="/mod" component={BoardModerator} />

                            <Route exact path="/registerMod" component={RegisterMod} />
                            <Route exact path="/admin" component={BoardAdmin} />
                            <Route exact path="/remotelogs" component={RemoteLogs} />
                            
                            {(userDefined && currentUser) && (
                                <Route path='*'>
                                    {showAdminBoard && (
                                        <Redirect to="/admin" />
                                    )}

                                    {showModeratorBoard && (
                                        <Redirect to="/mod" />
                                    )}

                                    {(currentUser && !showAdminBoard && !showModeratorBoard) && (
                                        <Redirect to="/sessions" />
                                    )}
                                </Route>
                            )}

                            {(userDefined && !currentUser) && (
                                <Redirect to="/about" />
                            )}
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;