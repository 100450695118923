import React, { Component } from "react";

import AuthService from "../services/auth.service";
import LogsService from "../services/logs.service";

import qs from "qs"

export default class UserLogs extends Component {
    constructor(props) {
        super(props);

        let curPage = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page;
        let id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

        if(curPage === undefined) {
            curPage = 0;
        }
        else {
            curPage = parseInt(curPage);
        }

        if(id === undefined) {
            id = "";
        }

        this.state = {
            content: "",
            logs: undefined,
            page: curPage,
            maxResults: 100,
            numPages: 1,
            totalLogs: 1,
            userId: id
        };
    }

    renderTableData() {
        return this.state.logs.map((log) => {
            const { uid, event, data, updated } = log;

            const localDate = new Date(updated);

            const strDate = localDate.toLocaleString();

            return (
                <tr key={uid}>
                    <td>{event}</td>
                    <td>{data}</td>
                    <td>{strDate}</td>
                </tr>
            )
        })
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser().user;

        if(user) {
            if(this.state.userId) {
                LogsService.getLogs(this.state.userId, this.state.page, this.state.maxResults).then(
                    response => {
                        let array = response.data.logs;

                        //V2 format update
                        let data = response.data;
                        if (data.data != null) {
                            let dataArray = data.data;
                            for (var j = 0; j < dataArray.length; j++) {
                                if (dataArray[j].type === "logs") {
                                    data = dataArray[j];
                                    array = data.entries;
                                }
                            }
                        }

                        for (var i = array.length - 1; i >= 0; --i) {
                            if (array[i].status === "deleted") {
                                array.splice(i,1);
                            }
                        }

                        this.setState({
                            logs: array,
                            totalLogs: data.resultSizeEstimate,
                            numPages: Math.ceil(data.resultSizeEstimate/this.state.maxResults)
                        });
                    },
                    error => {
                        this.setState({
                            content: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                        });
                    }
                );
            }
        }
        else {
            this.setState({
                content: "Error: Unauthorized"
            });
        }
    }

    render() {
        return (
            <div className="container mt-3">
                {this.state.content!=="" ? (
                    <header className="jumbotron">
                        <h3>{this.state.content}</h3>
                    </header>
                ):(
                    <div>
                        {(this.state.logs && this.state.logs.length > 0) && (
                            <div>
                                <header className="jumbotron">
                                    <h3>Logs for {this.state.userId}</h3>
                                </header>
                                <table className="table users-table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">EVENT</th>
                                            <th scope="col">DATA</th>
                                            <th scope="col">TIMESTAMP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableData()}
                                    </tbody>
                                </table>

                                <p>Showing {this.state.logs.length} of {this.state.totalLogs} results</p>

                                <nav aria-label="page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className={this.state.page <= 0 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page-1).toString() + "&id=" + this.state.userId}>Previous</a></li>

                                        { Array.from({length:this.state.numPages}, (_, index) => <li key={index} className={this.state.page === index ? "page-item active" : "page-item"}><a className="page-link" href={"?page=" + index + "&id=" + this.state.userId}>{index + 1}</a></li>) }

                                        <li className={this.state.page >= this.state.numPages-1 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page+1).toString() + "&id=" + this.state.userId}>Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        )}

                        {(this.state.logs && this.state.logs.length === 0) && (
                            <header className="jumbotron">
                                <h3>{this.state.userId} has no logs</h3>
                            </header>
                        )}
                    </div>
                )}
            </div>
        );
    }
}