import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../services/auth.service";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
            This field is required!
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
            This is not a valid email.
            </div>
        );
    }
};

const vname = value => {
    // const regexPattern = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
    const regexPattern = new RegExp('^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\'-]+$');
    
    if (value.length < 1 || value.length > 300) {
        return (
            <div className="alert alert-danger" role="alert">
            The full name must be between 1 and 300 characters.
            </div>
        );
    }
    else if(!regexPattern.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
            The full name must only contain letters and spaces.
            </div>
        );
    }
}

const vusername = value => {
    // const regexPattern = /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/;
    const regexPattern = new RegExp('^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$');

    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
            The username must be between 3 and 20 characters.
            </div>
        );
    }
    else if(!regexPattern.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
            The username must only contain letters and numbers.
            </div>
        );
    }
};

const vpassword = value => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
            The password must be between 6 and 40 characters.
            </div>
        );
    }
};

export default class RegisterMod extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            content: "",
            name: "",
            username: "",
            email: "",
            password: "",
            successful: false,
            message: "",
            loading: false
        };
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleRegister(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.registerMod(
                this.state.name,
                this.state.username,
                this.state.email,
                this.state.password
            ).then(
                response => {
                    this.setState({
                        message: response.data.message,
                        successful: true
                    });
                },
                error => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                    this.setState({
                        successful: false,
                        message: resMessage
                    });
                }
            );
        }
    }
    
    componentDidMount() {
        const user = AuthService.getCurrentUser().user;

        if(!user) {
            this.setState({
                content: "Error: Unauthorized"
            });
        }
    }

    render() {
        return (
            <div className="col-md-12">

                {this.state.content!=="" ? (
                    <div className="container mt-3">

                        <header className="jumbotron">
                            <h3>{this.state.content}</h3>
                        </header>
                    </div>
                ):(
                    <div className="card card-container">
                        <img src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" alt="profile-img" className="profile-img-card"/>

                        <Form onSubmit={this.handleRegister} ref={c => { this.form = c; }}>
                            {!this.state.successful && (
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name">Full Name</label>
                                        <Input type="text" className="form-control" name="name" value={this.state.name} onChange={this.onChangeName} validations={[required, vname]}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="username">Username</label>
                                        <Input type="text" className="form-control" name="username" value={this.state.username} onChange={this.onChangeUsername} validations={[required, vusername]}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <Input type="text" className="form-control" name="email" value={this.state.email} onChange={this.onChangeEmail} validations={[required, email]}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <Input type="password" className="form-control" name="password" value={this.state.password} onChange={this.onChangePassword} validations={[required, vpassword]}/>
                                    </div>

                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                            {this.state.loading && (<span className="spinner-border spinner-border-sm"></span>)}
                                            Create Moderator
                                        </button>
                                    </div>
                                </div>
                            )}

                            {this.state.message && (
                                <div className="form-group">
                                    <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                                        {this.state.message}
                                    </div>
                                </div>
                            )}

                            <CheckButton style={{ display: "none" }} ref={c => {this.checkBtn = c;}}/>
                        </Form>
                    </div>
                )}
            </div>
        );
    }
}