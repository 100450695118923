import React, { Component } from "react";

export default class About extends Component {
    render() {
        return (
            <div>
                <header className="bg-danger py-5 mb-5">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-lg-12">
                                <h1 className="display-4 text-white mt-5 mb-2">myLifeGuard</h1>
                                <p className="lead mb-5 text-white-50"></p>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-5">
                            <h2>Who We Are</h2>
                            <hr></hr>
                            <p>myLifeGuard is an early startup company, developing a discrete personal 24/7 lifeguard system that ensures a person, who is living alone or often alone, gets help quickly whenever needed, even if they are unable to request it in any way, while allowing others to know at all times whether or not the person is OK.</p>
                            <p>We are looking for entrepreneurs interested in creating a cool hi-tech product that keeps people safe, while building a new company.</p>
                            <p>Immediate needs are iOS mobile app development, marketing, and more.  Please contact us to find out more.</p>
                        </div>

                        <div className="col-md-4 mb-5">
                            <h2>Contact Us</h2>
                            <hr></hr>
                            <address>
                                <strong>myLifeGuard</strong>
                                <br></br>Waukesha County, WI
                                <br></br>
                            </address>

                            <address>
                                <span title="Email">Email: </span>
                                <a href="mailto:hr@peeblestech.com">hr@peeblestech.com</a>
                            </address>
                        </div>
                    </div>
                </div>
                
                <footer className="py-5 bg-dark">
                    <div className="container">
                        <p className="m-0 text-center text-white">Copyright &copy; myLifeGuard 2022</p>
                    </div>
                </footer>
            </div>
        );
    }
}