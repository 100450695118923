import React, { Component } from "react";
import { Modal, Alert } from 'react-bootstrap';
import { isEmail } from "validator";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Calendar from 'react-calendar';
import qs from "qs"

import SessionService from "../services/sessions.service";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

import deleteicon from "../images/icons8-remove.svg";
import minusicon from "../images/icons8-minus-64.png";
import upicon from "../images/icons8-thick-arrow-pointing-up-24.png";
import downicon from "../images/icons8-thick-arrow-pointing-down-24.png";

import 'react-calendar/dist/Calendar.css';

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
            This field is required!
            </div>
        );
    }
};

export default class Session extends Component {
    constructor(props) {
        super(props);

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDStart = this.onChangeDStart.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onChangeManual = this.onChangeManual.bind(this);
        this.onChangeAutomatic = this.onChangeAutomatic.bind(this);
        this.onChangeEscalationTime = this.onChangeEscalationTime.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);

        this.getSessions = this.getSessions.bind(this);
        this.getTileContent = this.getTileContent.bind(this);
        this.getSessionColor = this.getSessionColor.bind(this);

        this.isToday = this.isToday.bind(this);

        this.removeParticipant = this.removeParticipant.bind(this);
        this.moveParticipant = this.moveParticipant.bind(this);

        this.handleCloseSessionModal = this.handleCloseSessionModal.bind(this);
        this.handleAddParticipant = this.handleAddParticipant.bind(this);
        this.handleCreateSession = this.handleCreateSession.bind(this);
        this.handleDeleteSession = this.handleDeleteSession.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.handleOnActiveStartDateChange = this.handleOnActiveStartDateChange.bind(this);

        let curPage = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page;
        let id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

        if(curPage === undefined) {
            curPage = 0;
        }
        else {
            curPage = parseInt(curPage);
        }

        this.state = {
            content: "",
            monthSelected: new Date().getMonth(),
            sessions: undefined,
            sessionCirclesHash: undefined,
            page: curPage,
            maxResults: 1000,
            numPages: 1,
            totalSessions: 1,
            dateSelected: new Date(),
            showModal: false,
            showDeleteModal: false,
            deleteid: "",
            showAlert: false,
            title: "",
            dStart: undefined,
            duration: 0,
            enabled: true,
            participants: [],
            requirements: [],
            manualRequirementInterval: 30,
            automaticRequirementInterval: 30,
            searchError: "",
            searchText: "",
            curUserSelected: undefined,
            successful: false,
            message: "",
            loading: false,
            canScroll: true,
            currentUser: undefined,
            userId: id
        };
    }

    onChangeSearch(e) {
        this.setState({searchText: e.target.value, curUserSelected: undefined});

        if(e.target.value && e.target.value.trim() !== "") {
            try {
                if(isEmail(e.target.value)) {
                    UserService.findByEmail(this.state.userId, e.target.value).then(
                        response => {
                            let array = response.data.users;

                            //V2 format update
                            let data = response.data;
                            if (data.data != null) {
                                let dataArray = data.data;
                                for (var j = 0; j < dataArray.length; j++) {
                                    if (dataArray[j].type === "users") {
                                        data = dataArray[j];
                                        array = data.entries;
                                    }
                                }
                            }

                            if(array.length === 0) {
                                this.setState({searchError: "No user is associated with that email."});
                            }
                            else {
                                const user = array[0];
                                const curUserSelected = {"userId": user.uid, "name": user.name, "email": user.email, "priority": this.state.participants.length, "escalationTime": 0};
                                this.setState({searchError: "", curUserSelected: curUserSelected});
    
                                if(this.state.currentUser.email === this.state.curUserSelected.email) {
                                    this.setState({searchError: "You are already in this session."});
                                }
                            }
                        },
                        error => {
                            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                            this.setState({searchError: resMessage});
                        }
                    );
                }
                else {
                    this.setState({searchError: "No user is associated with that email."});
                }
            }
            catch(error) {
                
            }
        }
        else {
            this.setState({searchError: ""});
        }
    }

    onChangeTitle(e) {
        this.setState({
            title: e.target.value
        });
    }

    onChangeDStart(e) {
        this.setState({
            dStart: e.target.value
        });
    }

    onChangeDuration(e) {
        this.setState({
            duration: e.target.value
        });
    }

    onChangeEnabled() {
        this.setState({
            enabled: !this.state.enabled
        });
    }

    onChangeManual(e) {
        this.setState({
            manualRequirementInterval: e.target.value
        });
    }

    onChangeAutomatic(e) {
        this.setState({
            automaticRequirementInterval: e.target.value
        });
    }

    onChangeEscalationTime = index => e => {
        let participantsCopy = [...this.state.participants];
        let participant = {...participantsCopy[index]};
        participant.escalationTime = parseInt(e.target.value);
        participantsCopy[index] = participant;
        this.setState({participants: participantsCopy});
    };

    handleCloseSessionModal() {
        this.setState({
            canScroll: false,
            showModal: false,
            title: "",
            dStart: undefined,
            duration: 0,
            enabled: true,
            participants: [],
            requirements: [],
            manualRequirementInterval: 30,
            automaticRequirementInterval: 30,
            searchError: "",
            searchText: "",
            curUserSelected: undefined,
            successful: false,
            message: "",
            loading: false
        });
    }

    handleCloseDeleteModal() {
        this.setState({deleteid: "", showDeleteModal: false, canScroll: false});
    }

    handleAddParticipant(e) {
        e.preventDefault();

        for(let i = 0; i < this.state.participants.length; i++) {
            const participant = this.state.participants[i];
            if(participant.email === this.state.curUserSelected.email) {
                this.setState({searchText: "", curUserSelected: undefined});
                return;
            }
        }
    
        if(this.state.curUserSelected && !this.state.participants.includes(this.state.curUserSelected)) {
            const participant = this.state.curUserSelected;
            let tempParticipants = this.state.participants;
            tempParticipants.push(participant);
            this.setState({participants: tempParticipants, searchText: "", curUserSelected: undefined});
        }
    }

    handleDeleteSession() {
        SessionService.deleteSession(this.state.currentUser.uid, this.state.deleteid).then(
            response => {
                this.getSessions(false, this.state.dateSelected);
                this.setState({deleteid: "", showDeleteModal: false});
            },
            error => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                this.setState({
                    successful: false,
                    message: resMessage,
                    loading: false,
                    deleteid: "",
                    showDeleteModal: false
                });
            }
        );
    }

    handleDeleteBtn(id) {
        this.setState({showDeleteModal:true, deleteid: id});
    }

    handleCreateSession(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false,
            loading: true,
            requirements: []
        });

        this.form.validateAll();

        const duration = new Date(this.state.duration).getTime() - new Date(this.state.dStart).getTime();

        if (duration <= 0) {
            const resMessage = duration !== 0 ? "The ending time must be a time after the starting time!" : "The ending time and start time cannot be the same!";

            this.setState({
                successful: false,
                message: resMessage,
                loading: false
            });
        }
        else if (this.state.participants.length === 0) {
            const resMessage = "There must be at least one participant!";

            this.setState({
                successful: false,
                message: resMessage,
                loading: false
            });
        }
        else if (this.checkBtn.context._errors.length === 0) {
            const manualRequirement = { "name": "REQ_MANUAL", "interval": this.state.manualRequirementInterval*60000, "value": 0 };
            const automaticRequirement = { "name": "REQ_AUTOMATIC", "interval": this.state.automaticRequirementInterval*60000, "value": 0 };
            let tempRequirements = this.state.requirements;
            tempRequirements.push(manualRequirement);
            tempRequirements.push(automaticRequirement);
            this.setState({requirements: tempRequirements});

            SessionService.setSession(this.state.currentUser.uid, this.state.title, new Date(this.state.dStart), duration, this.state.enabled, 50, this.state.participants, this.state.requirements).then(
                response => {
                    this.getSessions(true, new Date());
                },
                error => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                    this.setState({
                        successful: false,
                        message: resMessage,
                        loading: false
                    });
                }
            );
        }
        else {
            this.setState({
                successful: false,
                loading: false,
                message: "Please verify whether the input fields are correct."
            });
        }
    }

    handleCalendarChange(date) {
        this.setState({dateSelected: date, canScroll: true});
    }

    handleOnActiveStartDateChange(data) {
        const date = data.activeStartDate;
        if(this.state.monthSelected !== date.getMonth()) {
            this.setState({monthSelected: date.getMonth(), sessions: []});
            this.getSessions(false, date);
        }
    }

    isToday(someDate) {
        const today = new Date();
        return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();
    }

    removeParticipant = index => e => {
        e.preventDefault();
        let participants = [...this.state.participants];
        participants.splice(index, 1);
        for(let i = 0; i < participants.length; i++) {
            participants[i].priority = i;
        }
        this.setState({participants: participants});
    }

    moveParticipant = (index, direction) => e => {
        e.preventDefault();
        let participants = [...this.state.participants];
        participants.move(index, index+direction);
        for(let i = 0; i < participants.length; i++) {
            participants[i].priority = i;
        }
        this.setState({participants: participants});
    }

    getTileContent(date, view) {
        const d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        const sessionCircle = this.state.sessionCirclesHash[d];

        if(view === "month" && sessionCircle) {
            return sessionCircle;
        }

        return <div></div>;
    }

    getSessionColor(index) {
        let color = "red";

        switch(index%4) {
            case 0:
                color = "red";
                break;
            case 1:
                color = "blue";
                break;
            case 2:
                color = "green";
                break;
            default:
                color = "purple";
                break;
        }

        return color;
    }

    getSessions(showAlert, dateInMonth) {
        const ONE_DAY = 86400000;
        const start = new Date(dateInMonth.getFullYear(), dateInMonth.getMonth(), 1).getTime() - ONE_DAY;
        const end = new Date(dateInMonth.getFullYear(), dateInMonth.getMonth() + 1, 0).getTime() + ONE_DAY;

        SessionService.getSessionsWithDateConstraints(this.state.currentUser.uid, this.state.page, this.state.maxResults, start, end).then(
            response => {
                let sessionCirclesHash = {};

                let array = response.data.sessions;

                //V2 format update
                let data = response.data;
                if (data.data != null) {
                    let dataArray = data.data;
                    for (var j = 0; j < dataArray.length; j++) {
                        if (dataArray[j].type === "sessions") {
                            data = dataArray[j];
                            array = data.entries;
                        }
                    }
                }

                for (var i = array.length - 1; i >= 0; --i) {
                    if (array[i].status === "deleted") {
                        array.splice(i,1);
                    }
                }

                for(let i = 0; i < array.length; i++) {
                    const session = array[i];
                    const date = new Date(session.start);
                    const d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                    var title = "";
                    if (session.title !== undefined)
                    title = session.title.trim().toUpperCase();
                    let color = this.getSessionColor(i);
                    let circles = [];
                    if(sessionCirclesHash[d]) {
                        circles = sessionCirclesHash[d];
                        circles.push(<p key={i} className={"session-circle " + color}>{title}</p>);
                        sessionCirclesHash[d] = circles;
                    }
                    else {
                        circles.push(<p key={i} className={"session-circle " + color}>{title}</p>);
                        sessionCirclesHash[d] = circles;
                    }
                }

                this.setState({
                    sessionCirclesHash: sessionCirclesHash,
                    sessions: array,
                    totalsessions: data.resultSizeEstimate,
                    numPages: Math.ceil(data.resultSizeEstimate/this.state.maxResults),
                    loading: false,
                    showAlert: showAlert
                });

                this.handleCloseSessionModal();
            },
            error => {
                this.setState({
                    content: (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
                    loading: false
                });
            }
        );
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser().user;

        if(user) {
            this.setState({currentUser: user}, () => {
                this.getSessions(false, new Date());
            });
        }
        else {
            this.setState({content: "Error: Unauthorized"});
        }
    }

    render() {
        let curDateSessions = [];

        const scrollTo = (ref) => {
            if (ref && !this.state.showModal && !this.state.showDeleteModal && this.state.canScroll) {
                ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }

        if(this.state.sessions) {
            const toFind = this.state.dateSelected;

            for(let i = 0; i < this.state.sessions.length; i++) {
                let session = this.state.sessions[i];

                if(new Date(session.start).getDate() === toFind.getDate()) {
                    session.color = this.getSessionColor(i);
                    curDateSessions.push(session);
                    session.participants.sort(function(participant1, participant2) {return participant1.priority - participant2.priority});
                }
            }

            curDateSessions.sort(function(session1, session2) {return new Date(session1.start) - new Date(session2.start)});
        }

        return (
            <div className="container mt-3">
                {this.state.content!=="" ? (
                    <header className="jumbotron">
                        <h3>{this.state.content}</h3>
                    </header>
                ):(
                    <div>
                        {this.state.sessions && (
                            <div>
                                <h2>
                                    Sessions
                                </h2>

                                <hr/>

                                {this.state.showAlert &&  (
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success!</Alert.Heading>
                                        <p>
                                            The session has successfully been created.
                                        </p>
                                    </Alert>
                                )}


                                <Modal show={this.state.showDeleteModal} scrollable={true} onHide={this.handleCloseDeleteModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>WARNING</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body>
                                            <p>Are you sure you would like to delete this session? This action cannot be undone.</p>
                                            
                                            <button className="btn btn-danger" onClick={this.handleDeleteSession}>Delete</button>
                                        </Modal.Body>
                                </Modal>


                                <Modal show={this.state.showModal} scrollable={true} onHide={this.handleCloseSessionModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create a Session</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body>
                                            <Form onSubmit={this.handleCreateSession} ref={c => { this.form = c; }}>
                                                <div className="form-group">
                                                    <label htmlFor="title">Title</label>
                                                    <Input type="text" className="form-control" name="title" value={this.state.title} onChange={this.onChangeTitle} validations={[required]}/>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="dstart">Starting Date and Time</label>
                                                    <Input type="datetime-local" className="form-control" name="dstart" value={this.state.dStart} onChange={this.onChangeDStart} validations={[required]}/>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="duration">Ending Date and Time</label>
                                                    <Input type="datetime-local" className="form-control" name="duration" min={this.state.dStart} value={this.state.duration} onChange={this.onChangeDuration} validations={[required]}/>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="manual">Manual Confirmation Interval (Minutes)</label>
                                                    <Input type="number" className="form-control" name="manual" value={this.state.manualRequirementInterval} onChange={this.onChangeManual} validations={[required]}/>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="automatic">Automatic Confirmation Interval (Minutes)</label>
                                                    <Input type="number" className="form-control" name="automatic" value={this.state.automaticRequirementInterval} onChange={this.onChangeAutomatic} validations={[required]}/>
                                                </div>

                                                <div>
                                                    <label htmlFor="participants">Participants</label>

                                                    <div className="card bg-light mb-3 noPadding participant-card">
                                                        <div className="card-header searchbar-box">
                                                            <div className="form-inline">
                                                                <input className="form-control participant-search-bar" type="text" placeholder="Find participants by email" aria-label="Search" list="userslist" onChange={this.onChangeSearch} value={this.state.searchText}></input>
                                                                <button className="btn btn-primary participant-add-btn" onClick={this.handleAddParticipant} disabled={!this.state.curUserSelected || (this.state.currentUser.email === this.state.curUserSelected.email)}>Add</button>
                                                            </div>
                                                            <p className="text-danger search-error">{this.state.searchError}</p>
                                                        </div>
                                                        <div className="card-body">
                                                            {this.state.participants.length !== 0 && (
                                                                <div>
                                                                    {this.state.participants.map((participant, index) => (
                                                                        <div key={participant.uid} className="card bg-light mb-3 noPadding participant-card">
                                                                            <div className="card-header">{participant.name}
                                                                                <button className="btn minus-btn float-right" onClick={this.removeParticipant(index)}>
                                                                                    <img alt="decrease priority" className="minus-img" src={minusicon}></img>
                                                                                </button>
                                                                                <button className="btn minus-btn float-right" onClick={this.moveParticipant(index, -1)}>
                                                                                    <img alt="increase priority" className="minus-img" src={upicon}></img>
                                                                                </button>
                                                                                <button className="btn minus-btn float-right" onClick={this.moveParticipant(index, 1)}>
                                                                                    <img alt="remove participant" className="minus-img" src={downicon}></img>
                                                                                </button>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="card-text">Priority: {participant.priority} {participant.priority === 0 && (<div>(top)</div>)} {participant.priority === this.state.participants.length-1 && participant.priority !== 0 && (<div>(lowest)</div>)}</div>
                                                                                <div className="card-text">Escalation Time (minutes): <Input className="participant-values" type="number" onChange={this.onChangeEscalationTime(index)} validations={[required]}></Input></div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            {this.state.participants.length === 0 && (
                                                                <p className="card-text">No participants have been added.</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group form-inline enabled-checkbox">
                                                    <label htmlFor="enabled">Enabled</label>
                                                    <input type="checkbox" className="form-control" name="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled}/>
                                                </div>

                                                <div className="form-group">
                                                    <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                                        {this.state.loading && (<span className="spinner-border spinner-border-sm"></span>)}
                                                        Create Session
                                                    </button>
                                                </div>

                                                {this.state.message && (
                                                    <div className="form-group">
                                                        <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}

                                                <CheckButton style={{ display: "none" }} ref={c => {this.checkBtn = c;}}/>

                                            </Form>
                                        </Modal.Body>
                                </Modal>

                                {(this.state.sessions && this.state.sessions.length < 1000) && (
                                    <button className="create-sesion-btn btn btn-primary" onClick={() => this.setState({showModal: true, showAlert: false})}>Create Session</button>
                                )}

                                {(this.state.sessions && this.state.sessions.length >= 1000) && (
                                    <p class="text-danger">You cannot create more than 1000 sessions a month. Please delete some sessions.</p>
                                )}

                                <Calendar
                                    onChange={(date) => this.handleCalendarChange(date)} 
                                    onActiveStartDateChange={(data) => this.handleOnActiveStartDateChange(data)}
                                    value={this.state.dateSelected}
                                    maxDetail="month"
                                    minDetail="year"
                                    next2Label={null} 
                                    prev2Label={null}
                                    tileContent={({date, view}) => this.getTileContent(date, view)}
                                    />

                                {curDateSessions.length > 0 && (
                                    <div ref={scrollTo}>
                                        <h3 className="sessions-header">{this.isToday(this.state.dateSelected) ? "Today's Sessions" : "Sessions for " + this.state.dateSelected.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } )}</h3>
                                        <hr/>
                                        <div>
                                            {
                                                curDateSessions.map((session, index) => (
                                                    <div key={session.uid + index} className="card bg-light mb-3 noPadding participant-card">
                                                        <h2 className="card-header">
                                                            <div className={"session-time " + session.color}>{((new Date(session.start).getHours()) + 24) % 12 || 12}:{parseInt(new Date(session.start).getMinutes()).pad()} {new Date(session.start).getHours() >= 12 ? 'pm' : 'am' }</div> 
                                                            <strong>{session.title}</strong> 
                                                            {this.state.currentUser.uid === session.userId ? 
                                                                (<button className="float-right deletesession-btn btn" onClick={()=>this.handleDeleteBtn(session.uid)}>
                                                                    <img alt="delete button" className="deletesession-img" src={deleteicon}></img>
                                                                </button>):
                                                                (<div className="session-owner">Created by {session.userId}</div>)
                                                            }
                                                        </h2>
                                                        <div className="card-body">
                                                            <h3>Participants</h3>
                                                            <hr/>
                                                            {session.participants.map((participant, index) => (
                                                                <div key={participant.uid} className="card bg-light mb-3 noPadding participant-card">
                                                                    <div className="card-header">{participant.name}</div>
                                                                    <div className="card-body">
                                                                        <div className="card-text">Email: {participant.email}</div>
                                                                        <div className="card-text">Priority: {participant.priority}</div>
                                                                        <div className="card-text">Escalation Time: {participant.escalationTime} {participant.escalationTime === 1 ? "minute": "minutes"}</div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}