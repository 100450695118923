import React, { Component } from "react";

import AuthService from "../services/auth.service";
import RemoteLogsService from "../services/remotelogs.service";
import DevicesService from "../services/devices.service";

import qs from "qs";

export default class RemoteLogs extends Component {
    constructor(props) {
        super(props);

        let curPage = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page;
        let id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

        this.handleChangeNotificationDate = this.handleChangeNotificationDate.bind(this);
        this.handleChangeDeviceSelection = this.handleChangeDeviceSelection.bind(this);
        this.handleGetRemoteLogs = this.handleGetRemoteLogs.bind(this);

        if(curPage === undefined) {
            curPage = 0;
        }
        else {
            curPage = parseInt(curPage);
        }

        this.state = {
            notificationData: '{"setting.pref_dbg_server_next_sync_time" : "0"}',
            userId: id,
            content: "",
            deviceToSendMessage: "all_devices",
            logs: undefined,
            devices: undefined,
            page: curPage,
            maxResults: 100,
            numPages: 1,
            totalLogs: 1
        };
    }

    handleChangeNotificationDate(event) {
        this.setState({notificationData: event.target.value});
    }

    handleChangeDeviceSelection(event) {
        this.setState({deviceToSendMessage: event.target.value});
    }

    handleGetRemoteLogs(event) {
        try {
            let data = JSON.parse(this.state.notificationData);
            data.deviceToSendMessage = this.state.deviceToSendMessage;
            RemoteLogsService.sendMessageToUserId(data, this.state.userId).then(response => {
                console.log('fcm', response);
            }, error => {
                console.error('fcm', error);
            });
        }
        catch(err) {
            alert("Error parsing json data");
        }
        event.preventDefault();
    }

    renderDeviceList() {
        return this.state.devices.map((device) => {
            const { uid, name, status, notificationToken } = device;

            if(notificationToken && status === "enabled") {
                return (
                    <option key={uid} value={uid}>{name}</option>
                );
            }
            else {
                return false;
            }
        });
    }

    renderTableData() {
        return this.state.logs.map((remoteLog) => {
            const { uid, userId, tag, level, message, updated } = remoteLog;

            const localDate = new Date(updated);

            const strDate = localDate.toLocaleString();

            return (
                <tr key={uid}>
                    <td>{userId}</td>
                    <td>{tag}</td>
                    <td>{level}</td>
                    <td>{message}</td>
                    <td>{strDate}</td>
                </tr>
            );
        });
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser().user;

        if(user) {
            const isAdmin = user.roles.length > 0 && user.roles[0].name === "ROLE_ADMIN";
            let userId = user.uid;

            if(isAdmin && this.state.userId !== undefined) {
                userId = this.state.userId;
            }
            RemoteLogsService.getRemoteLogs(userId, this.state.page, this.state.maxResults).then(
                response => {
                    console.log();

                    console.log('remote logs', response);
                    this.setState({
                        logs: response.data.data[0].entries,
                        totalLogs: response.data.data[0].resultSizeEstimate,
                        numPages: Math.ceil(response.data.data[0].resultSizeEstimate/this.state.maxResults)
                    });
                },
                error => {
                    console.error('getting remote logs error', error);
                    this.setState({
                        content: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                    });
                }
            );

            DevicesService.getDevices(userId).then(
                response => {
                    console.log('devices', response);
                    this.setState({
                        devices: response.data.data[0].entries
                    });
                },
                error => {
                    console.error('getting devices error', error);
                    this.setState({
                        content: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                    });
                }
            );
        }
        else {
            this.setState({
                content: "Error: Unauthorized"
            });
        }
    }

    render() {
        return (
            <div className="container mt-3">
                {this.state.content!=="" ? (
                    <header className="jumbotron">
                        <h3>{this.state.content}</h3>
                    </header>
                ):(
                    <div>
                        {this.state.logs && this.state.logs.length === 0 && (
                            <header className="jumbotron">
                                <h3>{this.state.userId} has no Remote Logs</h3>
                            </header>
                        )}

                        {this.state.logs && this.state.logs.length > 0 && (
                            <div>
                                <h2>
                                    {this.state.userId && (this.state.userId + "'s Remote Logs")}
                                </h2>

                                <hr/>
                            </div>
                        )}

                        {(this.state.devices && this.state.logs && this.state.userId) && (
                        <form className="form-inline" onSubmit={this.handleGetRemoteLogs}>
                            <input style={{marginBottom: "1rem"}} className="form-control" type="text" placeholder="Message data" value={this.state.notificationData} onChange={this.handleChangeNotificationDate}></input>
                            <select style={{marginBottom: "1rem", marginLeft: "1rem"}} className="form-select" aria-label="Default select example" value={this.state.deviceToSendMessage} onChange={this.handleChangeDeviceSelection}>
                                <option defaultValue value="all_devices">All Devices</option>
                                {this.renderDeviceList()}
                            </select>
                            <input style={{marginBottom: "1rem", marginLeft: "1rem"}} type="submit" className="btn btn-primary" value="Send Message"></input>
                        </form>)}

                        {this.state.logs && this.state.logs.length > 0 && (
                            <div>
                                <table className="table users-table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">USER ID</th>
                                            <th scope="col">TAG</th>
                                            <th scope="col">LEVEL</th>
                                            <th scope="col">MESSAGE</th>
                                            <th scope="col">UPDATED</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableData()}
                                    </tbody>
                                </table>

                                <p>Showing {this.state.logs.length} of {this.state.totalLogs} results</p>

                                <nav aria-label="page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className={this.state.page <= 0 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page-1).toString() + "&id=" + this.state.userId}>Previous</a></li>

                                        { Array.from({length:this.state.numPages}, (_, index) => <li key={index} className={this.state.page === index ? "page-item active" : "page-item"}><a className="page-link" href={"?page=" + index + "&id=" + this.state.userId}>{index + 1}</a></li>) }

                                        <li className={this.state.page >= this.state.numPages-1 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page+1).toString() + "&id=" + this.state.userId}>Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
