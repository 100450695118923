import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';

import UserService from "../services/user.service";

import qs from "qs";
import AuthService from "../services/auth.service";

export default class BoardAdmin extends Component {
    constructor(props) {
        super(props);

        let curPage = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page;

        if(curPage === undefined) {
            curPage = 0;
        }
        else {
            curPage = parseInt(curPage);
        }

        this.state = {
            content: "",
            users: [],
            maxResults: 100,
            page: curPage,
            numPages: 1,
            totalUsers: 1,
            showModal: false,
            delUserId: ""
        };
    }

    handleRemoteLogBtnClick(id) {
        this.props.history.push("/remotelogs?page=0&id=" + id);
    }

    handleLogBtnClick(id) {
        this.props.history.push("/userlogs?page=0&id=" + id);
    }

    handleDeleteBtnClick() {
        const uid = this.state.delUserId; 
        const user = AuthService.getCurrentUser();

        UserService.deleteUser(user.uid, uid).then(
            () => {
                this.setState({showModal: false});
                this.getAllUsers();
            },
            error => {
                this.setState({
                    content: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                });
            }
        );
    }

    renderTableData() {
        return this.state.users.map((user) => {
            const { uid, name, username, email, roles } = user

            let formattedRoles = [];

            roles.forEach((role) => {
                formattedRoles.push(role.name.substring(5));
            });

            return (
                <tr key={uid}>
                    <td>{uid}</td>
                    <td>{name}</td>
                    <td>{username}</td>
                    <td>{email}</td>
                    <td>{formattedRoles.join(", ")}</td>
                    <td>{(!formattedRoles.includes("ADMIN") && !formattedRoles.includes("MODERATOR")) && (<button type="button" className="btn btn-warning" onClick={()=>this.handleRemoteLogBtnClick(uid)}>Remote Logs</button>)}</td>
                    <td>{(!formattedRoles.includes("ADMIN") && !formattedRoles.includes("MODERATOR")) && (<button type="button" className="btn btn-info" onClick={()=>this.handleLogBtnClick(uid)}>Logs</button>)}</td>
                    <td><button type="button" className="btn btn-danger" onClick={()=>this.setState({showModal: true, delUserId: uid})}>Delete</button></td>
                </tr>
            )
        })
    }

    getAllUsers() {
        const user = AuthService.getCurrentUser();

        UserService.getUsers(user.uid, this.state.page, this.state.maxResults).then(
            response => {
                let array = response.data.users;
                //V2 format update
                let data = response.data;
                if (data.data != null) {
                    let dataArray = data.data;
                    for (var j = 0; j < dataArray.length; j++) {
                        if (dataArray[j].type === "users") {
                            data = dataArray[j];
                            array = data.entries;
                        }
                    }
                }

                this.setState({
                    users: array,
                    totalUsers: data.resultSizeEstimate,
                    numPages: Math.ceil(data.resultSizeEstimate/this.state.maxResults)
                });
            },
            error => {
                this.setState({
                    content: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                });
            }
        );
    }

    componentDidMount() {
        this.getAllUsers();
    }

    render() {
        return (
            <div className="container mt-3">
                {this.state.content!=="" ? (
                    <header className="jumbotron">
                        <h3>{this.state.content}</h3>
                    </header>
                ):(
                    <div>
                        {(this.state.users && this.state.users.length > 0) && (
                            <div>
                                <Modal show={this.state.showModal} onHide={()=>this.setState({delUserId:"", showModal: false})}>
                                    <Modal.Header>
                                        <Modal.Title>WARNING</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body>Are you sure you would like to delete user with id {this.state.delUserId}? This action cannot be undone.</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={()=>this.setState({showModal: false})}>
                                            No
                                        </Button>
                                        <Button variant="danger" onClick={()=>this.handleDeleteBtnClick()}>
                                            Yes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <table className="table users-table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">NAME</th>
                                            <th scope="col">USERNAME</th>
                                            <th scope="col">EMAIL</th>
                                            <th scope="col">ROLE</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.renderTableData()}
                                    </tbody>
                                </table>

                                <p>Showing {this.state.users.length} of {this.state.totalUsers} results</p>

                                <nav aria-label="page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className={this.state.page <= 0 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page-1).toString()}>Previous</a></li>

                                        { Array.from({length:this.state.numPages}, (_, index) => <li key={index} className={this.state.page === index ? "page-item active" : "page-item"}><a className="page-link" href={"?page=" + index}>{index + 1}</a></li>) }

                                        <li className={this.state.page >= this.state.numPages-1 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page+1).toString()}>Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}