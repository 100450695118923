import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v2/';

class RemoteLogsService {
    getRemoteLogs(userId, page, maxResults) {
        return axios.get(API_URL + userId + '/debugLogs?pageToken=' + page + '&maxResults=' + maxResults, { headers: authHeader() });
    }

    getRemoteLogsWithDateConstraints(userId, page, maxResults, start, end) {
        return axios.get(API_URL + userId + '/debugLogs?pageToken=' + page + '&maxResults=' + maxResults + "&start=" + start + "&end=" + end, { headers: authHeader() });
    }

    sendMessageToUserId(data, userId) {
        return axios.post(API_URL + "notifications/sendmessage", {
            "data": data,
            "userId": userId
        }, {headers: authHeader()})
    }
}

export default new RemoteLogsService();