import React, { Component } from "react";

import AuthService from "../services/auth.service";
import LogsService from "../services/logs.service";

import qs from "qs"

export default class Activity extends Component {
    constructor(props) {
        super(props);

        let curPage = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page;

        if(curPage === undefined) {
            curPage = 0;
        }
        else {
            curPage = parseInt(curPage);
        }

        this.state = {
            content: "",
            logs: [],
            page: curPage,
            maxResults: 100,
            numPages: 1,
            totalLogs: 1
        };
    }

    renderTableData() {
        return this.state.logs.map((log) => {
            const { uid, event, data, updated } = log;

            const localDate = new Date(updated);

            const strDate = localDate.toLocaleString();

            return (
                <tr key={uid}>
                    <td>{event}</td>
                    <td>{data}</td>
                    <td>{strDate}</td>
                </tr>
            )
        })
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser().user;

        if(user) {
            LogsService.getLogs(user.uid, this.state.page, this.state.maxResults).then(
                response => {
                    let logArray = response.data.logs;
                    //V2 format update
                    let data = response.data;
                    if (data.data != null) {
                        let typeArray = data.data;
                        for (var j = 0; j < typeArray.length; j++) {
                            if (typeArray[j].type === "logs") {
                                data = typeArray[j]; //resultSizeEstimate, etc. are here in V2
                                logArray = data.entries; //log entries are her in V2
                                break;
                            }
                        }
                    }

                    for (var i = logArray.length - 1; i >= 0; --i) {
                        if (logArray[i].status === "deleted") {
                            logArray.splice(i,1);
                        }
                    }

                    this.setState({
                        logs: logArray,
                        totalLogs: data.resultSizeEstimate,
                        numPages: Math.ceil(data.resultSizeEstimate/this.state.maxResults)
                    });
                },
                error => {
                    this.setState({
                        content: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                    });
                }
            );
        }
        else {
            this.setState({
                content: "Error: Unauthorized"
            });
        }
    }

    render() {
        return (
            <div className="container mt-3">
                {this.state.content!=="" ? (
                    <header className="jumbotron">
                        <h3>{this.state.content}</h3>
                    </header>
                ):(
                    <div>

                        <h2>
                            Activity
                        </h2>

                        <hr/>

                        {this.state.logs && this.state.logs.length === 0 && (
                            <p>No activity recorded</p>
                        )}

                        {this.state.logs && this.state.logs.length > 0 && (
                            <div>
                                <table className="table users-table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">EVENT</th>
                                            <th scope="col">DATA</th>
                                            <th scope="col">TIMESTAMP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableData()}
                                    </tbody>
                                </table>

                                <p>Showing {this.state.logs.length} of {this.state.totalLogs} results</p>

                                <nav aria-label="page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className={this.state.page <= 0 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page-1).toString()}>Previous</a></li>

                                        { Array.from({length:this.state.numPages}, (_, index) => <li key={index} className={this.state.page === index ? "page-item active" : "page-item"}><a className="page-link" href={"?page=" + index}>{index + 1}</a></li>) }

                                        <li className={this.state.page >= this.state.numPages-1 ? "page-item disabled" : "page-item"}><a className="page-link" href={"?page="+(this.state.page+1).toString()}>Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}