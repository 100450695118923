import axios from "axios";
import authHeader from "./auth-header";
import { messaging } from "../init-fcm";

import DevicesService from "../services/devices.service";

const API_URL = process.env.REACT_APP_API_URL + "/api/v2/auth/";
const API_URL_BASE = process.env.REACT_APP_API_URL + "/api/v2/";

class AuthService {
    clearLocalStorageAndRedirect() {
        localStorage.removeItem("user");
        localStorage.removeItem("token-expiry");
        localStorage.removeItem("deviceId");
        window.location.replace("/");
    }

    removeDeviceAndClearStorage() {
        if(this.getCurrentUser()) {
            DevicesService.deleteDevice(this.getCurrentUser().user.uid, DevicesService.getDeviceId()).then(
                response => {
                    this.clearLocalStorageAndRedirect();
                },
                error => {
                    console.log(error);
                    this.clearLocalStorageAndRedirect();
                }
            );
        }
        else {
            this.clearLocalStorageAndRedirect();
        }
    }

    removeRefreshToken() {
        axios.delete(API_URL + "logout", {headers: this.refreshAuthHeader()}).then(
            response => {
                this.removeDeviceAndClearStorage();
            },
            error => {
                console.log(error);
                this.removeDeviceAndClearStorage();
            }
        );
    }

    async logout() {
        if(messaging) {
            messaging.requestPermission()
                .then(()=>{
                    messaging.getToken().then(oldToken => {
                        try {
                            messaging.deleteToken(oldToken).then(() => {
                                this.removeRefreshToken();
                            });
                        }
                        catch (error) {
                            console.log(error);
                        }
                    })
                })
                .catch(error => {
                    this.removeRefreshToken();
                });
        }
        else {
            this.removeRefreshToken();
        }
    }

    login(username, password) {
        return axios 
            .post(API_URL + "signin", {
                username,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    let data = response.data;
                    data.id = data.userId;
                    data.uid = data.userId;
                    localStorage.setItem("user", JSON.stringify(data));
                }

                return response.data;
            });
    }

    register(name, username, email, password) {
        return axios
            .post(API_URL_BASE + "users", {
                name,
                username,
                email,
                password
            });
    }

    registerMod(name, username, email, password) {
        const roles = ["mod"];

        return axios
            .post(API_URL_BASE + "users", {
                name,
                username,
                email,
                password,
                roles
            }, 
            {headers: authHeader()});
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    refreshToken() {
        const userId = JSON.parse(localStorage.getItem('user')).user.uid;

        return axios
            .post(API_URL + "refreshtoken", {
                userId
            },
            {headers: this.refreshAuthHeader()});
    }

    refreshAuthHeader() {
        const user = JSON.parse(localStorage.getItem('user'));
    
        if (user && user.refreshToken) {
            return { Authorization: 'Bearer ' + user.refreshToken.token };
        } 
        else {
            return {};
        }
    }
}

export default new AuthService();