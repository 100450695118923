import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v2/';

class SessionService {
    setSession(userId, title, start, duration, enabled, priority, participants, requirements) {
        const session = {
            "uid": uuidv4(),
            "userId": userId,
            "updated": new Date(),
            "title": title,
            "start": start,
            "duration": duration,
            "enabled": enabled,
            "priority": priority,
            "participants": participants,
            "requirements": requirements,
            "status": "enabled"
        };
        let sessions = [];
        sessions.push(session);
        let entry = {
            "type": "sessions",
            "entries": sessions,
        }
        let data = [];
        data.push(entry);
        let body = {
            "data": data
        }

        return axios.put(API_URL + userId + '/sessions', {
            body
        }, {headers: authHeader()});
    }

    getSessions(userId, page, maxResults) {
        return axios.get(API_URL + userId + '/sessions?pageToken=' + page + '&maxResults=' + maxResults, { headers: authHeader() });
    }
    
    getSessionsWithDateConstraints(userId, page, maxResults, start, end) {
        return axios.get(API_URL + userId + '/sessions?pageToken=' + page + '&maxResults=' + maxResults + "&startActive=" + start + "&endActive=" + end, { headers: authHeader() });
    }

    deleteSession(userId, sessionId) {
        return axios.delete(API_URL + userId + '/sessions?uid=' + sessionId + '&purge=true', {headers: authHeader()});
    }
}

export default new SessionService();